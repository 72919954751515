<template>
  <div class="main">

          <div class="title">
      <div class="titleBox" @click="GoLunBoUrl(smallLB[0].Description)">
        <img src="../../assets/img/zmb_location.png" alt="" />
        <span>区域地理位置</span>
      </div>
      <div class="titleBox">
        <img src="../../assets/img/zmb_orientation.png" alt="" />
        <span>功能定位</span>
      </div>
      <div class="titleBox" @click="goZMlist('/ZMBcase')">
        <img src="../../assets/img/zmb_innovate.png" alt="" />
        <span>创新案例名录</span>
      </div>
      <div class="titleBox" @click="goZMlist('/ZMBpolicy')">
        <img src="../../assets/img/zmb_policy.png" alt="" />
        <span>相关政策</span>
      </div>
      <!-- <div class="titleBox" @click="goZMlist('/ZMBservice')">
        <img src="../../assets/img/zmb_services.png" alt="" />
        <span>企业服务</span>
      </div> -->
      <div class="titleBox" @click="goZMlist('/ZMBPolicyInformation')">
        <img src="../../assets/img/zmb_news.png" alt="" />
        <span>政策资讯</span>
      </div>
    </div>
    <!-- header end-->
        <div class="textbox" style="padding: 30px 0 0 0">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{path:'/freeTrade'}">自贸专栏</el-breadcrumb-item>
        <el-breadcrumb-item>功能定位</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- body start -->
    <div class="bodybox" style="padding-top:10px">
      <div class="textbox">
        <div class="layout-content service-ct">
          <div class="sr-grid has-gap36">
                <div id="filter" class="app-filter">
              <div class="tagbox" :style="iswelfare ? '': ' max-height: 70px;'">
                  <div class="tagleft" @click="getwelfare">
                    新闻类型 <i v-if="!iswelfare" class="el-icon-arrow-down"></i>
                    <i v-else class="el-icon-arrow-up"></i>
                  </div>
                <div class="tagright">
                  <span
                    :class="-1 == indexaa ? 'active' : ''"
                    @click="choeseaa(null, -1)"
                    >不限
                  </span>
                  <span
                    v-for="(item, index) in CateList"
                    :key="index"
                    :class="index == indexaa ? 'active' : ''"
                    @click="choeseaa(item,index)"
                    >{{ item.Name }}</span
                  >
                </div>
              </div>
            </div>
         
          </div>
        </div>
        <div class="textleft">
          <div v-for="(item, i) in NewsList" :key="i">
            <el-card
              shadow="hover"
              style="margin-bottom: 10px; cursor: pointer"
              class="box-card"
              v-if="item.CateName == '优惠政策'"
            >
              <div class="item" @click="InformationDetails(item.SysID)">
                <img :src="item.FirstImg" mode="aspectFill" alt="" />
                <div class="noimgbox">
                  <h3>
                    {{ item.Title }}
                  </h3>
                  <p>
                    {{ item.NewsKey }}
                  </p>
                  <div class="itembottom">
                    <span> {{ item.PolicyLevelName }}</span
                    ><span> 申报开始时间 {{ item.StarTime }} </span>

                  </div>
                </div>
              </div>
            </el-card>

            <el-card
              shadow="hover"
              style="margin-bottom: 10px; cursor: pointer"
              class="box-card"
              v-else
            >
              <div class="item" @click="InformationDetails(item.SysID)">
                <img :src="item.FirstImg" mode="aspectFill" alt="" />
                <div class="newsbox">
                  <h3>
                    {{ item.Title }}
                  </h3>
                  <p>
                    {{ item.Intro }}
                  </p>
                  <div class="itembottom">
                    <span>{{ item.PublishTime }}</span
                    ><span style="color: #366cb3; margin-left: 60px">{{
                      item.CateName
                    }}</span>
                  </div>
                </div>
              </div>
            </el-card>
          </div>

     


          
              <div class="more" v-if="IsShowMore == 1">
                <span @click="GetPageMore()"
                  >加载更多 <i class="el-icon-d-arrow-right"></i
                ></span>
              </div>
              <div class="more" v-if="IsShowMore != 1 && NewsList.length">
                <em>已加载全部 </em>
              </div>
              <div class="more" v-if="IsShowMore != 1 && !NewsList.length">
                <em>暂无数据 </em>
              </div>
        </div>
        <div class="textright">
          <el-card style="margin-bottom: 10px" class="box-card">
            <h4>最新资讯</h4>
          <div
                  v-for="(item, i) in NewNewsList"
                  :key="i"
                  class="items"
                  style="cursor: pointer"
                  @click="InformationDetail(item)"
                >
                  <span
                    style="font-size: 20px; font-weight: 800; color: #366cb3"
                    >·</span
                  >
                  <span
                    style="
                      width: 200px;
                      height: 32px;
                      text-overflow: -o-ellipsis-lastline;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      display: -webkit-box;
                      -webkit-line-clamp: 1;
                      line-clamp: 1;
                      -webkit-box-orient: vertical;
                    "
                    >{{ item.Title }}</span
                  >
                  <i>{{item.PublishTime}}</i>
                </div>
          </el-card>

          <el-card style="margin-bottom: 10px" class="box-card">
            <h4>最新注册企业</h4>
            <div
              v-for="(item, i) in NewCompanyList"
              :key="i"
              class="items"
              style="cursor: pointer"
              @click="getComInfor(item.CSysID)"
            >
              <span style="font-size: 20px; font-weight: 800; color: #366cb3;padding-right:3px"
                >·</span
              >
              {{ item.CName }}
            </div>
          </el-card>
        </div>
      </div>
    </div>
    <!-- body end -->
  </div>
</template>

<script>
import timeFormat from "../../util/time";
// 公共样式
import "../../assets/css/common.css";
export default {
  data() {
    return {
      activeIndex: "5",
      drawer: false,
      windowWidth: document.documentElement.clientWidth, //实时屏幕宽度
      NewsList: [],
      NewCompanyList: [],
      NewNewsList: [],
      CateSysID: "",
      CurCateName: "",
      CompanyTypeSysID: "",
      SaleTypeSysID: "",
      navlist: [
        {
          names: "首页",
          path: "/",
          id: "1",
        },
        {
          names: "政策资讯",
          path: "/news",
          id: "2",
        },
        {
          names: "产业载体",
          path: "/carrier",
          id: "3",
        },
        {
          names: "企业互动",
          path: "/interaction",
          id: "4",
        },
        {
          names: "金融服务",
          path: "/service",
          id: "5",
        },
        {
          names: "企业服务",
          path: "/enterpriseServices",
          id: "6",
        },
        {
          names: "招聘服务",
          path: "/recruit",
          id: "7",
        },
      ],
      CateList: [],
      indexaa: -1,
      CompanyTypeList: [],
      indexbb: -1,
      SaleTypeList: [],
      indexcc: -1,
      PageIndex: 1,
      PageSize: 5,
      IsShowMore: 1,
      smallLB: [],
      iswelfare:false,
    };
  },

  watch: {
    windowWidth(val) {
      let that = this;
      console.log("实时屏幕宽度：", val);
      if (val > 990) {
        that.drawer = false;
      }
    },
  },
  methods: {
       getwelfare() {
      this.iswelfare = !this.iswelfare;
    },
       //轮播跳转
    GoLunBoUrl(url) {
      if (url != null && url != "" ) {
        window.location.href=url;
      }
    },
    getLBsmail() {
      let that = this;
      that
        .$post("api/SysNews/List", {
          State: true,
          Type: "自贸区地理位置",
          IsHome: true,
          Sort:'Sort ASC'
        })
        .then(function (res) {
          console.log(res);
          let data = res.Obj;
          data.forEach((v) => {
            if (v.Img) {
              v.Img = that.urlOA + v.Img.substr(1);
            }
          });
          that.smallLB = data;
        });
    },
      goZMlist(url) {
      this.$router.push(url);
    },
    //   企业详情
    getComInfor(id) {
      this.$router.push({ path: "/enterpriseDetails", query: { SysID: id } });
    },
    choeseaa(item, index) {
      console.log(index);

      if (item != null) {
        this.CateSysID = item.SysID;
        this.CurCateName = item.Name;
      } else {
        this.CateSysID = "";
        this.CurCateName = "";
      }
      this.CompanyTypeSysID = "";
      this.SaleTypeSysID = "";
      this.indexaa = index;
      this.PageIndex = 1;
      this.NewsList = [];
      this.GetNewsList();
    },
    choesebb(item, index) {
      console.log(index);
      if (item != null) {
        this.CompanyTypeSysID = item.SysID;
      } else {
        this.CompanyTypeSysID = "";
      }
      this.indexbb = index;
      this.PageIndex = 1;
      this.NewsList = [];
      this.GetNewsList();
    },
    choesecc(item, index) {
      console.log(index);
      if (item != null) {
        this.SaleTypeSysID = item.SysID;
      } else {
        this.SaleTypeSysID = "";
      }
      this.indexcc = index;
      this.PageIndex = 1;
      this.NewsList = [];
      this.GetNewsList();
    },
    GetPageMore() {
      this.PageIndex = this.PageIndex + 1;
      this.GetNewsList();
    },
    GetCateList() {
      let that = this;
      that
        .$post("/api/Dictionary/List", {
          State: true,
          Type: "ZMGndwType",
          Sort: "Sort asc",
        })
        .then(function (data) {
          console.log(data);
          that.CateList = data.Obj;
        })
        .catch((error) => {
          console.log(error);
        });

      that
        .$post("/api/Dictionary/List", {
          State: true,
          // Type: "NewsCompanyType",
          Type: "NewsPolicyLevel", //政策级别
          Sort: "Sort asc",
        
        })
        .then(function (data) {
          that.CompanyTypeList = data.Obj;
        })
        .catch((error) => {
          console.log(error);
        });

      that
        .$post("/api/Dictionary/List", {
          State: true,
          Type: "NewsSaleType",
          Sort: "Sort asc",
        })
        .then(function (data) {
          that.SaleTypeList = data.Obj;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 获取新闻列表
    GetNewsList() {
      let that = this;
      this.$post("/api/ZMGndw/List", {
        State: true,
        CateSysID: this.CateSysID,
       
        Sort: "CreateOn desc",
        PageSize: this.PageSize,
        PageIndex: this.PageIndex,
       
      })
        .then(function (data) {
          console.log(data);
          data.Obj.forEach((v) => {
            if (v.FirstImg) {
              v.FirstImg = that.urlOA + v.FirstImg.substr(1);
            }

            v.PublishTime = timeFormat.timeFormat(v.PublishTime, 1);
            v.StarTime = timeFormat.timeFormat(v.StarTime, 1);
            that.NewsList.push(v);
          });
          if (data.Obj.length == that.PageSize) {
            that.IsShowMore = 1;
          } else {
            that.IsShowMore = 0;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleSelect(e) {
      let that = this;
      console.log(e);
      that.$router.push(e);
      setTimeout(() => {
        that.drawer = false;
      }, 500);
    },
    getlogin() {
      this.$router.push("/login");
    },
    register() {
      this.$router.push("/register");
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    InformationDetails(SysID) {
      this.$router.push({
        path: "/ZMBoriDetail",
        query: { SysID: SysID },
      });
    },
        InformationDetail(item) {
      let type = item.Type
      console.log(type);
      if (type==1) {
           this.$router.push({
        path: "/ZMBpolicyDetail",
        query: { SysID: item.SysID },
      });
      }else{
           this.$router.push({
        path: "/InformationDetails",
        query: { SysID: item.SysID },
      });
      }
   
    },
    GetNewNewsList() {
      let that = this;
      that
        .$post("/api/News/List", {
          State: true,
          Sort: "CreateOn desc",
          PageSize: 8,
          PageIndex: 1,
        })
        .then(function (data) {
          console.log(data);
          that.NewNewsList = data.Obj;
          that.NewNewsList.forEach((v) => {
            v.FirstImgUrl = that.urlOA + v.FirstImgUrl.substr(1);
            v.PublishTime = timeFormat.timeFormat(v.PublishTime, 3);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    GetNewCompanyList() {
      let that = this;
      that
        .$post("/api/UserInfo/List", {
          CState:1,
          State: true,
          Sort: "CreateOn desc",
          PageSize: 8,
          Type: 1,
          PageIndex: 1,
        })
        .then(function (data) {
          console.log(data);
          that.NewCompanyList = data.Obj;
          that.NewCompanyList.forEach((v) => {
            v.CompanyLogo = that.urlOA + v.CompanyLogo.substr(1);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    var that = this;
     that.getLBsmail()
    that.NewsList = [];
    that.GetCateList();
    that.GetNewsList();
    that.GetNewCompanyList();
    that.GetNewNewsList();
    // <!--把window.onresize事件挂在到mounted函数上-->
    window.onresize = () => {
      return (() => {
        window.fullHeight = document.documentElement.clientHeight;
        window.fullWidth = document.documentElement.clientWidth;
        that.windowHeight = window.fullHeight; // 高
        that.windowWidth = window.fullWidth; // 宽
      })();
    };
  },
};
</script>


<style scoped  lang="scss">

.title {
  width: 100%;
  height: 80px;
  background-color: #eee;

  display: flex;
  justify-content: center;
  .titleBox {
    margin-top: 20px;
    height: 40px;
    margin-left: 40px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-right: 10px;
    img {
      height: 40px;
    }
    span {
      margin-left: 10px;
      font-weight: 550;
    }
  }
}
.tagbox {
  display: flex;
  border-bottom: 1px dashed #ccc;
  padding: 20px 0;
  .tagleft {
    flex: 1;

    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    margin-top: 10px;
    i{
      display: none;
    }
  }
  .tagright {
    flex: 9;
    span {
      float: left;
      margin-right: 30px;
      width: 100px;
      text-align: center;
      padding: 3px 0;
      cursor: pointer;
      font-size: 14px;
      border-radius: 4px;
      margin-top: 10px;
      
    }
  }
}

.domain dt {
  min-height: 40px;
  width: 100px;
  span {
    display: inline-block;
    height: 30px;
    line-height: 30px;
  }
}
.level dt {
  min-height: 40px;
  width: 100px;
  span {
    display: inline-block;
    height: 30px;
    line-height: 30px;
  }
}
.secondbox {
  padding-left: 50px;
  box-sizing: border-box;
}
.sr-card {
  float: left;
  transition: all 0.5s linear;
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  border-radius: 3px;
  box-shadow: 0 2px 3px #ddd;
  width: 276px;
  margin-left: 34px;
  margin-top: 20px;
  border: 1px solid #ddd;
  cursor: pointer;
  .sr-card-image {
    height: 154px;
    img {
      height: 100%;
      width: 100%;
    }
  }
  .sr-card-contents {
    background-color: #fcfcfc;
    padding: 8px;
    h3 {
      height: 48px;
      font-size: 16px;
      color: #323232;
      font-weight: bold;

      line-height: 1.6;
      margin-bottom: 8px;
    }
  }
  .sr-card-content {
    height: 142px;
    padding: 8px;
    h3 {
      height: 48px;
      font-size: 16px;
      color: #323232;
      font-weight: bold;

      line-height: 1.6;
      margin-bottom: 8px;
    }
    .tags {
      line-height: 22px;
      font-size: 14px;
      overflow: hidden;
      color: #ff6633;
      margin-bottom: 8px;
      span {
        padding-right: 6px;
        height: 20px;
        font-size: 12px;
      }
    }
    p {
      color: #666;
      margin-bottom: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 14px;
    }
  }
  .sr-card-footer {
    background-color: #f3f7fb;
    padding: 8px;

    .fl {
      float: left;
      width: 60px;
      height: 60px;
      background-image: url(../../assets/img/zt4.jpg);
      background-size: 100%;
      background-position: center;
      background-repeat: no-repeat;
      border: 1px solid #f8f8f8;
    }
    .company {
      margin-top: 10px;
      font-size: 14px;
      height: 40px;
      display: flex;
      justify-items: center;
      line-height: 20px;
      padding-left: 10px;
      box-sizing: border-box;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}
.sr-card:hover {
  box-shadow: 0 6px 40px #ddd;
}
.sr-row {
  margin-left: -18px;
  margin-right: -18px;
  margin-bottom: 30px;
}
.sr-row:before,
.sr-row:after {
  content: "";
  display: table;
  clear: both;
}
.row_item {
  float: left;
  cursor: pointer;
  display: block;
  width: 172px;
  height: 100px;
  background-color: #f9f9f9;
  border: 1px solid #f0f0f0;
  margin-bottom: 20px;
  margin-left: 36px;
  img {
    display: inline-block;
    width: 100%;
    height: 100%;
  }
}

// banner
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__container {
  // height: 200px;
  height: 1000px !important;
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.textbox {
  width: 1230px;
  margin-left: 50%;
  transform: translateX(-50%);
  // background-color: #475669;
  padding-top: 10px;
  overflow: hidden;
}
.textleft {
  float: left;
   margin-top: 20px;
  width: 900px;
  min-height: 600px;
}
.textright {
   margin-top: 20px;
  float: left;
  width: 300px;
  // height: 600px;
  margin-left: 20px;
}
.item {
  position: relative;
  height: 160px;
  width: 100%;
  img {
    position: absolute;
    left: 0;
    top: 0;
    height: 160px;
    width: 280px;
  }
  .noimgbox {
    position: absolute;
    left: 310px;
    top: 0;
    height: 100%;
    width: 100%;
    padding: 6px;
    box-sizing: border-box;
    h3 {
      position: absolute;
      top: 0;
      width: 560px;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      font-size: 18px;
      font-weight: bold;
      color: #323232;
    }
    p {
      position: absolute;
      top: 60px;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .itembottom {
      position: absolute;

      height: 40px;
      bottom: 0;
      line-height: 40px;
      font-size: 14px;
      span {
        padding: 3px 20px;
        background-color: #ff6633;
        margin-right: 20px;
        color: #fff;
        font-size: 14px;
        border-radius: 1px;
      }
    }
  }
  .newsbox {
    position: absolute;
    left: 310px;
    height: 100%;
    width: 550px;
    padding: 6px;
    box-sizing: border-box;
    h3 {
      position: absolute;
      top: 0;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      font-size: 18px;
      font-weight: bold;
      color: #323232;
    }
    p {
      position: absolute;
      top: 60px;
    
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .itembottom {
      position: absolute;

      height: 40px;
      bottom: 0;
      line-height: 40px;
      font-size: 14px;
    }
  }
}
.items {
  font-size: 0.8rem;
  line-height: 36px;
  height: 36px;

  overflow: hidden;

  span {
    float: left;

    line-height: 32px;
    height: 32px;
    margin-left: 3px;
  }
  i {
    float: right;
    font-style: normal;
  }
}
.items:hover,
.item h3:hover,
.item p:hover {
  color: #2b60a5;
}

.aaa {
  display: none;
}
.bannerbox img {
  height: 100%;
}


@media (max-width: 500px) {
  .main .textbox {
    width: 100%;
    .el-breadcrumb{
    padding-left: 10px;
    box-sizing: border-box;
  }
  }
  .textleft {
     min-height: 60px;
    float: left;
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
  }
  .textright {
    float: left;
    width: 100%;
    // height: 600px;
    margin-left: 0;
    overflow: hidden;
  }
  .box-card {
    width: 100%;
    box-sizing: border-box;
  }
  .item {
    height: 60px;
    width: 280px;
    img {
      position: absolute;
      left: 0;
      top: 0;
      height: 60px;
      width: 80px;
    }

    .noimgbox {
           position: absolute;
      left: 90px;
      height: 100%;
      width: 240px;
      padding: 2px;
      box-sizing: border-box;
      h3 {
        position: absolute;
        top: 0;
        text-overflow: -o-ellipsis-lastline;
        width: 230px;
        box-sizing: border-box;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
        font-size: 14px;
      }
      p {
        position: absolute;
        top: 20px;
        height: 20px;
        width: 230px;
        line-height: 20px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
        font-size: 12px;
      }
      .itembottom {
        position: absolute;
        height: 20px;
        top: 40px;
        line-height: 20px;
        font-size: 10px;
        span {
          font-size: 12px;
          padding: 2px 4px;
        }
      }
    }
    .newsbox {
      position: absolute;
      left: 90px;
      height: 100%;
      width: 240px;
      padding: 2px;
      box-sizing: border-box;

      h3 {
        position: absolute;
        width: 230px;
        top: 0;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
        font-size: 14px;
      }
      p {
        position: absolute;
        top: 20px;
        height: 20px;
        width: 230px;
        line-height: 20px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
        font-size: 12px;
      }
      .itembottom {
        position: absolute;

        height: 40px;
        top: 36px;
        line-height: 40px;
        font-size: 12px;
        span {
          padding: 3px 10px;
          background-color: #ff6633;
          margin-right: 5px;
          color: #fff !important;
          font-size: 12px;
          border-radius: 1px;
        }
      }
    }
  }
  .sr-card {
    width: 334px;
  }
  .row_item {
    width: 152px;
    height: 70px;
    background-color: #f9f9f9;
    border: 1px solid #f0f0f0;
    margin-bottom: 20px;
    margin-left: 33px;
    img {
      display: inline-block;
      width: 100%;
      height: 100%;
    }
  }

  .tagbox {
    display: flex;
    border-bottom: 1px dashed #ccc;
    padding: 20px 0;
    overflow: hidden;
    box-sizing: border-box;
    .tagleft {
      flex: 1;
      justify-content: center;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 700;
      margin-top: 10px;
        i{
        display: block;
        font-weight: 700;
        margin-left: 4px;
      }
    }
    .tagright {
      flex: 3;
      span {
        float: left;
        margin-right: 10px;
        width: 100px;
        text-align: center;
        padding: 3px 0;
        cursor: pointer;
        font-size: 14px;
        border-radius: 4px;
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
  }
 .title {
    width: 100%;
    height: 60px;
    background-color: #eee;
    box-sizing: border-box;
    min-width: 300px;
    display: flex;
    justify-content: center;
    .titleBox {
      margin-top: 20px;
      height: 20px;
       margin-left: 10px;
    margin-right: 0;
      display: flex;
      align-items: center;
      cursor: pointer;
      padding-right: 10px;
      img {
        display: none;
        height: 20px;
        width: 25px;
      }
      span {
        font-size: 12px;
        margin-left: 10px;
        font-weight: 550;
      }
    }
  }
}
</style>